<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col md="12" class="pb-0">
                <v-label>Category Icon</v-label>
                <div class="imageBox mt-2">
                  <img
                    v-if="imageFileUrl"
                    :src="imageFileUrl"
                    alt=""
                    @click="onImagePick"
                  />

                  <v-file-input
                    class="mt-1"
                    v-if="imageFileUrl == undefined"
                    v-model="imageFile"
                    outlined
                    prepend-inner-icon="mdi-camera"
                    prepend-icon=""
                    @change="onChangeImage"
                    placeholder="Category Icon"
                  ></v-file-input>
                </div>
              </v-col>
              <v-col cols="12" class="pt-0">
                <label for="">Category Name: </label>
                <v-text-field
                  v-model="name"
                  outlined
                  filled
                  placeholder="Category Name"
                  :rules="titleRules"
                  class="mt-3"
                ></v-text-field>
              </v-col>
              
              <v-col class="pt-0 btnBox">
                <v-btn class="viewMore" v-if="loading" loading> </v-btn>
                <v-btn class="viewMore" v-if="!loading" @click="validate">
                  {{ headercontent.title }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddCategory",

  data: () => ({
    valid: true,
    name: "",
    headercontent: {
      title: "Add Category",
      addlink: "/dashboard/category/add",
      downloadlink: true,
    },
    titleRules: [(v) => !!v || "Sub-Category Name is required"],
    statusRules: [(v) => !!v || "Status is required"],
    statuses: [
      { name: "Yes", status: true },
      { name: "No", status: false },
    ],
    status: true,
    id: null,
    loading: false,
    imageFileUrl: undefined,
    imageFile: undefined,
    brand: undefined,
    brands: [],
  }),
  methods: {
    ...mapActions(["postCategory", "fetchCategoryById", "updateCategory"]),
    async validate() {
      this.loading = true;
      let fd = new FormData();
      if (this.$refs.form.validate()) {
        fd.append("name", this.name);
        if (this.imageFile != undefined) {
          fd.append("image", this.imageFile);
        }
        if (this.$route.params.id != undefined) {
          fd.append("_id", this.$route.params.id);
        }
        await this.postCategory(fd);
        this.loading = false;
      }
    },
    onChangeImage() {
      if (this.imageFile != undefined) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.imageFileUrl = fileReader.result;
        });
        fileReader.readAsDataURL(this.imageFile);
      } else {
        this.imageFileUrl = undefined;
      }
    },
    onImagePick() {
      this.imageFileUrl = undefined;
    },
  },

  computed: {
    ...mapGetters(["allLoadingCurd", "allSingleCategory"]),
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },

  async created() {
    if (this.$route.params.id != undefined) {
      await this.fetchCategoryById(this.$route.params.id);
      this.name = this.allSingleCategory.results.name;
      this.imageFileUrl = this.allSingleCategory.results.image;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.uploadedImage {
  width: 150px;
  padding: 20px;
}
.btnBox {
  margin-top: -15px;
}
.viewMore {
  min-height: 50px;
  width: 200px;
}
.imagebox {
  display: flex;
  max-width: 30%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 20px;
  label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
.rmimagebox {
  position: relative;
  width: 200px;
  img {
    width: 100%;
  }
  .v-icon {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.form-section {
  label {
    display: block;
    margin-bottom: 10px;
  }
}
.imageBox {
  img {
    border: 1px solid #efefef;
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 10px;
    margin-right: 10px;
  }
}
</style>
